
import { defineComponent } from "vue";
import moment from "moment";
import { DissolutionService } from "@/core/services/Dissolution.service";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "dissolution-report-table-list",
  data() {
    return {
      moment,
    };
  },
  props: {
    items: {
      type: Array,
    },
  },
  methods: {
    remove(id) {
      Swal.fire({
        text: "Bạn chắc chắn muốn xoá?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Xoá",
        cancelButtonText: "Huỷ",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-active-light",
        },
      }).then(async (result) => {
        if (result.value) {
          await DissolutionService.delete(id);
          this.$emit("update-table");
        }
      });
    },
  },
});
