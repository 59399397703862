
import { defineComponent, onMounted, ref } from "vue";

import { DissolutionService } from "@/core/services/Dissolution.service";
import DissolutionReportTableList from "./dissolution-report-table/DissolutionReportTableList.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "dissolution-report-list",
  components: { DissolutionReportTableList },
  setup() {
    const plans = ref<any[]>([]);

    const getData = async () => {
      const {
        data: {
          data: { data: allPlans },
        },
      } = await DissolutionService.list();
      const data = allPlans
        .map((item: any, index) => {
          item.stt = index + 1;
          return item;
        })
        .filter((it) => it.ke_hoach_sua_chua);
      plans.value = [...data];
    };

    onMounted(async () => {
      setCurrentPageTitle("Trang quản lý biên bản giải thể");
      getData();
    });

    return {
      plans,
      getData,
    };
  },
});
